<template>
  <NotificationBase
    :notification
    :icon
    :compact
    link="/personal/bets?sportTab=processed"
  >
    <template #subject>
      {{ subject }}
    </template>
    <i18n-t
      v-if="notification.type === 'betUnacceptedExpress'"
      keypath="notifications.body.betUnacceptedExpress"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else
      keypath="notifications.body.betProcessedWinLoseExpress"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { BetExpressNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: BetExpressNotification
  compact?: boolean
}>()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)

const icon = computed(() => ({
  name: 'cup-solid' as const,
  currencyIcon: currencyIcon.value,
}))

const { t } = useI18n()
const subject = computed(
  () =>
    ({
      betProcessedWinExpress: t('notifications.subject.betProcessedWinExpress'),
      betProcessedLossExpress: t(
        'notifications.subject.betProcessedLossExpress',
      ),
      betUnacceptedExpress: t('notifications.subject.betUnacceptedExpress'),
    })[notification.type],
)
</script>
